<template>
    <Setup-Step
        fluid
        @next="f => f()"
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        step="order-fulfill"
        title="Order Fulfill">
        <template v-slot:body>
            <BT-Order-Cycle-Header showTrimmings highlightFulfill title="Order Pipeline: Fulfiller" />

            <Setup-Block>
                <v-list dense>
                    <v-list-item><strong class="mr-2">Scope:</strong>Defined by the supplier for each individual customer.</v-list-item>
                    <v-list-item><strong class="mr-2">Options: </strong>Auto or Manual</v-list-item>
                </v-list>

                
                <v-divider class="my-4" />

                <p>
                    After being approved an order needs to be assigned a fulfillment service.
                    In most cases the supplier is also the fulfillment service.
                    The supplier can have BlitzIt Web assign a fulfillment service automatically or have it wait for a user to select who is going to fulfill the order.
                </p>
                <p>
                    In order for BlitzIt Web to automatically assign a fulfillment service, you need to set up fulfillment pointers.  
                </p>
                <!-- If you fulfill all your own orders then create a pointer that covers the whole area where your customers are and that points to your own company as the fulfillment service. -->
                
                <v-divider class="my-4" />

                <Setup-Example>
                    Peter's Pears fulfills all their orders themselves and doesn't want to bother with having to select who is going to fulfill the order.  They opt to have BlitzIt Web automatically proceed with assigning themselves as the fulfillment service for every order that is delivered in Australia.
                </Setup-Example>

                <Setup-Example>
                    Andy's Apricots fulfills all theirs orders in Victoria themselves, but they use South West Logistical Services to store their stock and to manage the picking, packing, and freighting of interstate orders.  
                    They want BlitzIt Web to automatically assign the fulfillment service and so they create one pointer that covers Victoria and points to themself as the fulfillment service and another pointer that covers all of Australia and points to South West Logistical Services.
                </Setup-Example>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Fulfill-Setup',
    components: {
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>